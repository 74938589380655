import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { api } from "../../middleware/api";

const EmployerForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    mobileNumber: '',
    industry: '',
    scenario: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData(prevState => ({
      ...prevState,
      mobileNumber: value
    }));
  };

  const validateForm = () => {
    if (!formData.firstName.trim()) return "First Name is required";
    if (!formData.companyName.trim()) return "Company Name is required";
    // if (!formData.email.trim()) return "Email is required";
    // if (!/\S+@\S+\.\S+/.test(formData.email)) return "Email is invalid";
    if (!formData.mobileNumber) return "Mobile Number is required";
    // if (!formData.industry) return "Industry is required";
    // if (!formData.scenario) return "Scenario is required";
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    // API call to verify the code and start the accounts receivable call
    api.post("ar-start/", { method: 'ar-start', data: formData })
    .then((res) => {
      if (res?.data?.ok) {
        setFormSubmitted(true);
        setError('');
      } else {
        throw new Error('Verification failed or call could not be started');
      }
    }).catch((err) => {
      console.log("ERROR IS", err)
      setError("An error occurred setting up the call.");
    });
  };

  const initiateSignup = () => {
    window.location.href = '/signup?accounts=true';
  }

  const industries = [
    'Technology', 'Healthcare', 'Finance', 'Education', 'Retail',
    'Manufacturing', 'Construction', 'Hospitality', 'Other'
  ];

  const scenarios = [
    'Invoice owing 30 days for $4000',
    'Late payment on $10,000 contract',
    'Unpaid services worth $2500',
    'Overdue account of $7500',
    'Outstanding balance of $5000 for 45 days'
  ];

  if (formSubmitted) {
    return (
      <div className="verification-section">
        <h2>Call Initiated</h2>
        <p>Our AI Agent will now call you to give a demonstration of the accounts receivable system.</p>
        <p>Once you try it out, the next step is to Signup to the system and connect your accounting system, e.g. Xero</p>
        <button onClick={() => initiateSignup()} className="border-0 px-4 py-2 primaryButton">
          Connect now
        </button>
      </div>
    );
  }

  return (
    <div className="employer-section">
      <h1 className="main-heading">Try our Free Live Demo!</h1>
      <div className="verification-section">
        <h2>How the Demo works...</h2>
        <style>
          {`
            ol {
              padding-left: 20px;
            }
            ol li {
              margin-bottom: 10px;
            }
          `}
        </style>
        <ol>
          <li>Just fill out the fields below, press 'Submit' (Rest assured your information is kept strictly confidential)</li>
          <li>Our simulator will call you from 0438 845 713</li>
          <li>Answer and interact with our simulator</li>
          <li>Experience our AI driven accounts first hand</li>
        </ol>
        <p>Start your demo now and discover how MPLOI can revolutionize your accounts receivable process!</p>
      </div>
      <form onSubmit={handleSubmit} className="employer-form">
        <div className="form-group">
          <label htmlFor="firstName">Your First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="firstName">Your Company Name</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <PhoneInput
            country={'au'}
            value={formData.mobileNumber}
            onChange={handlePhoneChange}
            inputProps={{
              name: 'mobileNumber',
              required: true,
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="industry">What is your industry?</label>
          <select
            id="industry"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            className="form-control"
            required
          >
            <option value="">Select an industry</option>
            {industries.map((industry, index) => (
              <option key={index} value={industry}>{industry}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="scenario">Example scenario</label>
          <select
            id="scenario"
            name="scenario"
            value={formData.scenario}
            onChange={handleChange}
            className="form-control"
            required
          >
            <option value="">Select a scenario</option>
            {scenarios.map((scenario, index) => (
              <option key={index} value={scenario}>{scenario}</option>
            ))}
          </select>
        </div>

        {error && <div className="alert alert-danger">{error}</div>}

        <button type="submit" className="border-0 px-4 py-2 primaryButton">
          I'm ready to receive a call
        </button>
      </form>
    </div>
  );
};

export default EmployerForm;
